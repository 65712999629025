body {
    --square-items: 6;
    --rectangle-items: 3;
    --grid-square-items: 6;
    --grid-rectangle-items: 3;
    @media (max-width: 1366px) {
        --square-items: 5;
        --grid-square-items: 5;
    }

    @media (max-width: 1024px) {
        --square-items: 4;
        --rectangle-items: 1;
        --grid-square-items: 4;
        --grid-rectangle-items: 1;
    }

    @media (max-width: 992px) {
        --square-items: 4.2;
        --rectangle-items: 1.5;
        --grid-square-items: 2;
    }

    @media (max-width: 620px) {
        --square-items: 2.2;
    }
}