@import './vars.scss';
[data-theme='gray-dark'] {
  --primary-bg: #3b3f46;
  --secondary-bg: #40454d;
  --layout-bg: #2a2e34;
  --sidebar-bg: #3b3f46;
  --header-bg: #3b3f46;
  --box-item-bg: #40454d;
  --text-primary: #{$white};
  --text-secondary: #7e7e7e;
  --link-text-hover: #{$orange};
  --icon-primary: #7e7e7e;
  --icon-secondary: #7e7e7e;
  --border-primary: #7e7e7e;
  --gradient-primary: linear-gradient(132deg, #fe4251 5.09%, #feca30 96.09%);

  --secondary-bg-alpha-50: rgba(64, 69, 77, 0.5);
  --secondary-bg-alpha-80: rgba(64, 69, 77, 0.8);
}
