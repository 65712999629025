@import './vars.scss';
[data-theme='dark'] {
  --primary-bg: #{$black-1f};
  --secondary-bg: #{$blue-38};
  --layout-bg: #{$black-2b};
  --sidebar-bg: #{$black-1f};
  --header-bg: #{$black-2b};
  --box-item-bg: #{$black-26};
  --text-primary: #{$white};
  --text-secondary: #{$gray-90};
  --link-text-hover: #{$orange};
  --icon-primary: #{$gray-90};
  --icon-secondary: #{$gray-49};
  --border-primary: #{$blue-38};
  --gradient-primary: linear-gradient(132deg, #fe4251 5.09%, #feca30 96.09%);

  --secondary-bg-alpha-50: #{$blue-38-alpha-50};
  --secondary-bg-alpha-80: #{$blue-38-alpha-80};
  --primary-bg-alpha-60: #{$black-1f-alpha-60};
  --layout-bg-alpha-50: #{$black-2b-alpha-50};
}
