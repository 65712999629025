@import './reset.scss';
@import './fonts.scss';
@import './vars.scss';
@import './carousel.scss';
@import './theme/light.scss';
@import './theme/dark.scss';
@import './theme/gray-dark.scss';

// @import '~survey-react/modern.css';
html {
  // font-family: 'Be Vietnam', sans-serif;
  font-family: 'Open Sans', sans-serif;

  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -font-smoothing: antialiased;
  font-variant-ligatures: none;
  /* Font variant */
  -webkit-font-variant-ligatures: none;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

:root {
  --white: #{$white};
  --yellow: #f3e500;
  --red: #ff0000;
  --transparent: rgba(0, 0, 0, 0);
  --logo-color: #{$orange};
  --alpha-50: rgba(0, 0, 0, 0.5);
  --white-alpha-50: rgba(255, 255, 255, 0.5);
  --white-alpha-30: rgba(255, 255, 255, 0.3);
  --white-alpha-5: rgba(255, 255, 255, 0.05);
  --white-e3: #e3e4e5;
  --white-e1: #e1e2e2;
  --white-e3-alpha-80: rgba(227, 228, 229, 0.8);
  --black-77: #777777;
  --black-33: #333333;
  --black-33-alpha-70: rgba(51, 51, 51, 0.7);
  --gradient-green-yellow: linear-gradient(135deg, #69ac77 0%, #ffef7b 100%);
  --gradient-pink-yellow: linear-gradient(135deg, #ed4264 0%, #ffedbc 100%);
  --gradient-pink-blue: linear-gradient(135deg, #ff6e7f 0%, #bfe9ff 100%);
  --gradient-yellow-light: linear-gradient(133.38deg, #f09819 0%, #edde5d 97.33%);
  --gradient-red-light: linear-gradient(135deg, #ff512f 0%, #dd2476 100%);
  --gradient-black-33: linear-gradient(180deg, rgba(51, 51, 51, 0.6) 0%, rgba(51, 51, 51, 0) 100%);

  --functional-btn-background: #{$functional-btn-background};
  --box-shadow: #{$box-shadow};

  --zIndex-popup-player: #{$zIndex-popup-player};
  --zIndex-global-player: #{$zIndex-global-player};
  --zIndex-progress-bar: #{$zIndex-progress-bar};
  --zIndex-drawer: #{$zIndex-drawer};
  --zIndex-popper: #{$zIndex-popper};

  --mobile-zIndex-drawer: #{$mobile-zIndex-drawer};
  --mobile-zIndex-header: #{$mobile-zIndex-header};

  --border-radius-small: #{$border-radius-small};
  --border-radius-medium: #{$border-radius-medium};
  --border-radius-xLarge: #{$border-radius-xLarge};

  --scrollbar-track: #{$scrollbar-track};
  --scrollbar-thumb: #{$scrollbar-thumb};
  --media-mask: #{$media-mask};

  --header-height: #{$header-height};
  --footer-height: #{$footer-height};
  --footer-border: #{$footer-border};
  --global-player-height: #{$global-player-height};
  --drawer-width: #{$drawer-width};
  --right-sidebar-width: #{$right-sidebar-width};
  --shortened-right-sidebar-width: #{$shortened-right-sidebar-width};
  --shortened-drawer-width: #{$shortened-drawer-width};
  --search-box-width: #{$search-box-width};
  --player-dummy-trigger-height: #{$player-dummy-trigger-height};

  --mobile-footer-height: #{$mobile-footer-height};
  --mobile-global-player-height: #{$mobile-global-player-height};

  

  --hidden-screen-flex: flex;
  --visibility-screen-flex: none;
  --font-size-normal: 14px;
  --font-size-medium: 22px;
  --font-size-large: 32px;
  --line-height-normal: 19px;
  --line-height-medium: 30px;
  --line-height-large: 44px;
  --layout-offset-padding: calc((100vw - (var(--drawer-width) + var(--right-sidebar-width) + 1308px)) / 2);

  @media (max-width: 1920px) {
    --layout-offset-padding: 50px;
  }

  @media (max-width: 1366px) {

    --layout-offset-padding: 36px;
    --font-size-normal: 14px;
    --font-size-medium: 20px;
    --font-size-large: 32px;
    --line-height-normal: 19px;
    --line-height-medium: 27px;
    --line-height-large: 35px;
  }

  @media (max-width: 1024px) {
    --hidden-screen-flex: none;
    --visibility-screen-flex: flex;
  }

  @media (max-width: 992px) {
    --layout-offset-padding: 16px;

    --font-size-normal: 16px;
    --font-size-medium: 20px;
    --font-size-large: 30px;
    --line-height-normal: 22px;
    --line-height-medium: 27px;
    --line-height-large: 35px;
  }
}

html.block-scroll,
body.block-scroll {
  height: 100% !important;
  overflow: hidden !important;
  position: fixed !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--secondary-bg) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--secondary-bg) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--text-secondary) !important;
}

body {
  background-color: var(--layout-bg);
  color: var(--text-primary);
  overflow: hidden;
  scrollbar-color: var(--scrollbar-thumb) transparent;
  scrollbar-width: thin;
  font-size: var(--font-size-normal);
  line-height: var(--line-height-normal);
  
  ::-webkit-scrollbar {
    width: 4px;
    background: var(--transparent);
  }

  ::-webkit-scrollbar-track {
    border-radius: var(--border-radius-medium);
    background: var(--transparent);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-medium);
    background-color: var(--border-primary);
  }

  ::placeholder {
    color: var(--text-secondary);
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--text-secondary);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--text-secondary);
  }

  i {
    font-family: 'Muvi Icon', sans-serif;
  }
}

a:hover {
  color: var(--link-text-hover);
}

svg {
  fill: currentColor;
  color: currentColor;
  transition: all 0.2s;
}
.hasHover {
  fill: currentColor;
  color: currentColor;
  transition: all 0.2s;
  &:hover {
    path,
    rect,
    span,
    i {
      fill: var(--link-text-hover);
      color: var(--link-text-hover) !important;
    }
    line {
      stroke: var(--link-text-hover);
    }
  }
}
path,
rect,
line {
  fill: currentColor;
  color: currentColor;
}
