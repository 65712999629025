@import './vars.scss';
[data-theme='light'] {
  --primary-bg: #{$white};
  --secondary-bg: #{$gray-e3};
  --layout-bg: #{$white-f9};
  --sidebar-bg: #{$white};
  --header-bg: #{$white-f9};
  --box-item-bg: #{$white};
  --text-primary: #{$black};
  --text-secondary: #{$gray-77};
  --link-text-hover: #{$orange};
  --icon-primary: #{$gray-77};
  --icon-secondary: #{$gray-e1};
  --border-primary: #{$gray-e3};
  --gradient-primary: linear-gradient(132deg, #fe4251 5.09%, #feca30 96.09%);

  --secondary-bg-alpha-50: #{$gray-e3-alpha-50};
  --secondary-bg-alpha-80: #{$gray-e3-alpha-80};
  --primary-bg-alpha-60: #{$white-alpha-60};
  --layout-bg-alpha-50: #{$white-f9-alpha-50};
}
