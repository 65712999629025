/* be-vietnam-italic - vietnamese_latin */
// @font-face {
//   font-family: 'Be Vietnam';
//   font-style: italic;
//   font-weight: 400;
//   src: url('/static/fonts//be-vietnam-v2-vietnamese_latin-italic.eot'); /* IE9 Compat Modes */
//   src: local(''),
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-italic.woff') format('woff'), /* Modern Browsers */
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-italic.svg#BeVietnam') format('svg'); /* Legacy iOS */
// }
/* be-vietnam-regular - vietnamese_latin */
// @font-face {
//   font-family: 'Be Vietnam';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('/static/fonts//be-vietnam-v2-vietnamese_latin-regular.eot'); /* IE9 Compat Modes */
//   src: local(''),
//     url('/static/fonts//be-vietnam-v2-vietnamese_latin-regular.eot?#iefix')
//       format('embedded-opentype'),
//     /* IE6-IE8 */ url('/static/fonts//be-vietnam-v2-vietnamese_latin-regular.woff2') format('woff2'),
//     /* Super Modern Browsers */ url('/static/fonts//be-vietnam-v2-vietnamese_latin-regular.woff')
//       format('woff'),
//     /* Modern Browsers */ url('/static/fonts//be-vietnam-v2-vietnamese_latin-regular.ttf')
//       format('truetype'),
//     /* Safari, Android, iOS */
//       url('/static/fonts//be-vietnam-v2-vietnamese_latin-regular.svg#BeVietnam') format('svg'); /* Legacy iOS */
// }
/* be-vietnam-500 - vietnamese_latin */
// @font-face {
//   font-family: 'Be Vietnam';
//   font-style: normal;
//   font-weight: 500;
//   src: url('/static/fonts//be-vietnam-v2-vietnamese_latin-500.eot'); /* IE9 Compat Modes */
//   src: local(''),
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-500.woff') format('woff'), /* Modern Browsers */
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('/static/fonts//be-vietnam-v2-vietnamese_latin-500.svg#BeVietnam') format('svg'); /* Legacy iOS */
// }
/* be-vietnam-600 - vietnamese_latin */
// @font-face {
//   font-family: 'Be Vietnam';
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
//   src: url('/static/fonts//be-vietnam-v2-vietnamese_latin-600.eot'); /* IE9 Compat Modes */
//   src: local(''),
//     url('/static/fonts//be-vietnam-v2-vietnamese_latin-600.eot?#iefix') format('embedded-opentype'),
//     /* IE6-IE8 */ url('/static/fonts//be-vietnam-v2-vietnamese_latin-600.woff2') format('woff2'),
//     /* Super Modern Browsers */ url('/static/fonts//be-vietnam-v2-vietnamese_latin-600.woff')
//       format('woff'),
//     /* Modern Browsers */ url('/static/fonts//be-vietnam-v2-vietnamese_latin-600.ttf')
//       format('truetype'),
//     /* Safari, Android, iOS */
//       url('/static/fonts//be-vietnam-v2-vietnamese_latin-600.svg#BeVietnam') format('svg'); /* Legacy iOS */
// }
@font-face {
  font-family: 'Muvi Icon';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('/static/fonts/muvi-icon-v20.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/static/fonts//OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/static/fonts//OpenSans-Bold.ttf');
}
